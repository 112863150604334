import ApplicationController from '@controllers/application_controller.js';
import { KeyArrayStorage } from '@js/helpers/storage_helper.js'

export default class extends ApplicationController {
    static targets = ['pcInput']
    static outlets = [ 'autocomplete--custom' ]

    initialize() {
        let _this = this;

        this.storage = new KeyArrayStorage('data-main-cepage-ids', 'data-cepage-synonyms-ids')

        // Get already added cepages
        $('.cepage-select').each((_, el) => {
            let [type, cepageId] = _this.getTypeCepageId($(el))
            _this.storage.add(_this.typeToKey(type),
                              cepageId)
        })
    }

    appendRow(event) {
        // Double tilde to convert NaN to Zero
        let sum = this.getSelectValues().reduce((x, y) => ~~x + ~~y, 0)

        if (sum >= 100) {
            toastr.warning("La somme de vos pourcentages est déjà égale ou supérieur à 100%")
            return;
        }

        this.stimulate("nestedField#append", event.currentTarget)
            .then((promise) => {
                setTimeout(() => { this.setDataAttributes() },
                           500)
            })

    }

    resetOptions(event) {
        // Remove selected cepage from cepages set
        this.setHiddenFields($(`#${$(event.currentTarget).data('remove-target')}`),
                             null,
                             null)
        this.stimulate("nestedField#remove", event.currentTarget)
            .then(() => {
                this.autocompleteCustomOutlets.forEach((outlet) => outlet.disableSelected())
            })
    }

    check(event) {
        // Get form
        const form = event.currentTarget.form

        // Check if all lines are filled
        if (this.hasEmptySelect(form)) {
            toastr.error("Vous ne pouvez pas enregistrer avec un cépage vide")
            return;
        }

        // Check select values
        let values = this.getSelectValues()
        if ( values.some( (elem) => (elem > 100) || (elem < 0) )) {
            toastr.error("Vous avez des pourcentages incohérents (> 100% ou < 0%)")
            return;
        }


        let sum = values.reduce((x, y) => ~~x + ~~y, 0)

        if (sum < 100 && sum > 0) {
            Swal.fire({
                text: "La somme des pourcentages de votre assemblage est inférieure à 100% ! Voulez-vous continuer ?",
                showCancelButton: true,
                confirmButtonText: 'Oui',
                cancelButtonText: 'Non',
                confirmButtonColor: 'var(--bs-primary, #3085d6)'
            }).then((result) => {
                if (!result.isConfirmed) {
                    return;
                }

                form.submit()
            })
        } else if ((sum > 100) || ((sum === 100) && (values.some( v => !v)))) {
            toastr.error("La somme de vos pourcentages est supérieur à 100% ou inférieur à 0%")
        } else {
            form.submit()
        }
    }

    hasEmptySelect(form) {
        // First find rows where user does not click on destroy row
        let $rows = $('.cepage-select').not(':has(input:regex(name,destroy)[value=1])');

        return $rows.find('.select-cepage')
                    .toArray()
                    .some(elem => !$(elem).val())
    }

    getSelectValues() {
        return $(this.pcInputTargets).not(':hidden')
                                     .map((_, elem) => parseInt($(elem).val()))
                                     .get()
    }

    fillHiddenValues(event) {
        let $currentTarget = $(event.currentTarget)

        if ($currentTarget.hasClass('disabled')) {
            let message = "Vous avez déjà ajouté ce cépage ou un de ses synonymes"
            let cevent = new CustomEvent("notify",
                                         { detail: {
                                                message: message
                                         }})
            document.dispatchEvent(cevent)
            return;
        }

        let $row = $currentTarget.closest('.cepage-select')

        // Fill hidden fields
        this.setHiddenFields($row,
                             event.params.cepageId,
                             event.params.cepageType)

        // Fill input value
        this.setInputVal($row,
                         $currentTarget.find('.cepage-name').text(),
                         true)

        // Hide dropdown
        $row.find('ul.autocomplete-group')
            .attr('hidden', true)

        $row.find('.reset-select')
            .removeClass('d-none')
    }

    setDataAttributes() {
        $('.autocomplete-cepage').attr('data-autocomplete--custom-selected-value',
                                       JSON.stringify(this.storage))
    }

    resetSelect(event) {
        let $currentTarget = $(event.currentTarget);
        let $row = $currentTarget.closest('.cepage-select');

        this.setHiddenFields($row, null, null);

        // Remove disabled on autocomplete outlets
        this.autocompleteCustomOutlets.forEach((outlet) => outlet.disableSelected())

        this.setInputVal($row, '', false);

        $row.find('.reset-select')
            .addClass('d-none');
    }

    setHiddenFields($row, cepageId, cepageType) {
        // Remove from storage before changing val
        let [oldCepageType, oldCepageId] = this.getTypeCepageId($row)
        this.storage.remove(this.typeToKey(oldCepageType),
                            oldCepageId)

        // Set values
        let hiddenFields = $row.find('.d-none.cepage-attributes')
        hiddenFields.find('#cepage_id')
                    .val(cepageId)
        hiddenFields.find('#cepage_type')
                    .val(cepageType)

        // Check assemblage
        this.stimulate('Form::Millesime#check_assemblage', this.element,
                                                           this.cepages())

        // Set storage
        this.storage.add(this.typeToKey(cepageType),
                         cepageId)

        // Set data attributes for autocomplete divs
        this.setDataAttributes()
    }

    cepages() {
        return $('.select-row:visible').map((_, dom) => {
            let cepage_id = $(dom).find('#cepage_id').val()
            return cepage_id ? [[ parseInt(cepage_id),
                                  $(dom).find('#cepage_type').val(),
                                  parseInt($(dom).find('.cepage-pc input').val()) || null ]]
                             : null
        }).toArray()
    }

    checkAssemblage(event) {
        this.stimulate('Form::Millesime#check_assemblage', this.element,
                                                           this.cepages())
    }

    getTypeCepageId($row) {
        let hiddenFields = $row.find('.d-none.cepage-attributes')

        let type     = hiddenFields.find('#cepage_type').val()
        let cepageId = hiddenFields.find('#cepage_id').val()

        return [type, cepageId]
    }

    setInputVal($row, value, disabled) {
        let $input = $row.find('input#autocomplete')
        $input.val(value)
        $input.attr('disabled', disabled)
    }

    typeToKey(type) {
        return type === 'Cepage' ? 'data-main-cepage-ids'
                                 : 'data-cepage-synonyms-ids'
    }
}