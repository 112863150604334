import ApplicationController from '@controllers/application_controller.js';

export default class extends ApplicationController {
    static outlets = ['form--assemblages']

    initialize() {
        $(this.element).on('submit', (e) =>{
            e.preventDefault();

            if (!this.check_assemblage()) { return; }

            // Open domain rules modal if cuvee rules is empty
            let condition = $("#cuvee-rules").is(':empty')
                         && $('.modal#cuvee-domain-rules').length == 0
                         && !$('#domainRules').hasClass('opacity-50')

            if (condition) {
                toastr.warning("Voulez-vous ajouter des règles issues du domaine à cette cuvée ?")
                $('#domainRules').trigger('click')

                // Remove close modal button
                setTimeout(() =>{
                    // Scroll To bottom
                    window.scrollTo({ left: 0,
                                      top: document.body.scrollHeight,
                                      behavior: "smooth" });
                    $('.modal-header .btn-close').remove()
                    $('.modal-footer .btn-rouge').removeClass('d-none')

                    let submitButton = $(this.element).find(':submit')
                                                      .clone();

                    submitButton.attr('data-bs-dismiss', 'modal')
                    submitButton.attr('data-action', 'click->edit-modal#dismiss')
                    $('.modal-footer .btn-primary').replaceWith(submitButton);
                }, 250)
            } else {
                Turbo.navigator.submitForm(this.element);
            }

        })

        $(this.element).on('turbo:submit-end', (e) => {
            if (!e.detail.success) {
                window.scrollTo({ left: 0,
                                  top: 0,
                                  behavior: "smooth" });
            }

        })
    }

    deleteRule(event) {
        $(event.currentTarget).closest('.row[certification-name]')
                              .remove()
    }

    check_assemblage() {
        let assemblageController = this.formAssemblagesOutlet
        if (assemblageController.hasEmptySelect(this.element)) {
            toastr.error("Vous ne pouvez pas enregistrer avec un cépage vide");
            return false;
        }

        let values = assemblageController.getSelectValues()
        if ( values.some( (elem) => (elem > 100) || (elem < 0) )) {
            toastr.error("Vous avez des pourcentages incohérents (> 100% ou < 0%)")
            return false;
        }

        let sum = values.reduce((x, y) => ~~x + ~~y, 0)
        if ((sum > 100) || ((sum === 100) && (values.some( v => !v)))) {
            toastr.error("La somme de vos pourcentages est supérieur à 100% ou inférieur à 0%")
            return false;
        } else {
            return true;
        }
    }
}