import ApplicationController from '@controllers/application_controller.js'

export default class extends ApplicationController {
    static values = {
        attachmentId: { type: Number, default: -1 }
    }

    delete(event) {
        event.preventDefault()
        Swal.fire({
            title: "Êtes vous sûr de vouloir supprimer l'objet attaché ?",
            showCancelButton: true,
            confirmButtonText: 'Oui',
            cancelButtonText: 'Non',
            confirmButtonColor: 'var(--bs-primary, #3085d6)'
        }).then((result) => {
            if ( !result.isConfirmed || this.attachmentIdValue === -1 ) { return ;}

            this.stimulate("Attachments#delete", this.attachmentIdValue)
        })
    }
}